var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getComponentsByType, } from '@wix/platform-editor-helpers';
import { setDemoSuggestions } from './setDemoSuggestions';
import { getAppWidgetWrappedSearchBoxDefinition, getSearchBoxComponentDefinition, } from './componentDefinitions';
import { ComponentType } from './types';
import { addBreadcrumb } from './sentry';
import { isWrappingWithAppWidgetEnabled } from './experiments';
var SEARCH_BOX_SIZE = {
    width: 305,
    height: 45,
};
export function getAllSearchBoxes(editor) {
    return __awaiter(this, void 0, Promise, function () {
        var ex_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('getAllSearchBoxes: start');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, getComponentsByType(editor, ComponentType.SearchBox)];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    ex_1 = _a.sent();
                    addBreadcrumb('getAllSearchBoxes() failed');
                    return [3 /*break*/, 5];
                case 4:
                    addBreadcrumb('getAllSearchBoxes: finish');
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/, []];
            }
        });
    });
}
export function isSearchBox(editor, componentRef) {
    return __awaiter(this, void 0, Promise, function () {
        var componentType, ex_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('isSearchBox: start');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, editor.SDK.components.getType(editor.token, {
                            componentRef: componentRef,
                        })];
                case 2:
                    componentType = _a.sent();
                    return [2 /*return*/, componentType === ComponentType.SearchBox];
                case 3:
                    ex_2 = _a.sent();
                    addBreadcrumb('isSearchBox() failed');
                    return [2 /*return*/, false];
                case 4:
                    addBreadcrumb('isSearchBox: finish');
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export function addSearchBox(editor) {
    return __awaiter(this, void 0, Promise, function () {
        var headerRef, headerLayout, searchBoxLayout, getSearchBoxDefinition, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('addSearchBox: start');
                    return [4 /*yield*/, editor.SDK.siteSegments.getHeader()];
                case 1:
                    headerRef = _a.sent();
                    return [4 /*yield*/, editor.SDK.components.layout.get(editor.token, {
                            componentRef: headerRef,
                        })];
                case 2:
                    headerLayout = _a.sent();
                    searchBoxLayout = {
                        x: headerLayout.bounding.width - SEARCH_BOX_SIZE.width,
                        y: headerLayout.bounding.height > SEARCH_BOX_SIZE.height
                            ? Math.round(headerLayout.bounding.height / 2 - SEARCH_BOX_SIZE.height / 2)
                            : 0,
                        width: SEARCH_BOX_SIZE.width,
                        height: SEARCH_BOX_SIZE.height,
                    };
                    getSearchBoxDefinition = isWrappingWithAppWidgetEnabled(editor.experiments)
                        ? getAppWidgetWrappedSearchBoxDefinition
                        : getSearchBoxComponentDefinition;
                    return [4 /*yield*/, editor.SDK.components.add(editor.token, {
                            pageRef: headerRef,
                            componentDefinition: getSearchBoxDefinition({
                                appDefinitionId: editor.appDefinitionId,
                                layout: searchBoxLayout,
                            }),
                        })];
                case 3:
                    result = _a.sent();
                    addBreadcrumb('addSearchBox: finish');
                    return [2 /*return*/, result];
            }
        });
    });
}
export function initSearchBoxSuggestions(editor, componentRef) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('initSearchBoxSuggestions: start');
                    return [4 /*yield*/, editor.SDK.components.data.get(editor.token, {
                            componentRef: componentRef,
                        })];
                case 1:
                    data = _a.sent();
                    if (!((data === null || data === void 0 ? void 0 : data.suggestionsEnabled) === undefined)) return [3 /*break*/, 3];
                    return [4 /*yield*/, editor.SDK.components.data.update(editor.token, {
                            componentRef: componentRef,
                            data: {
                                suggestionsEnabled: false,
                            },
                        })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [4 /*yield*/, setDemoSuggestions(editor, componentRef)];
                case 4:
                    _a.sent();
                    addBreadcrumb('initSearchBoxSuggestions: finish');
                    return [2 /*return*/];
            }
        });
    });
}
